import React from "react"
import ImageMeta from "../../components/ImageMeta"
import { Link } from "gatsby"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  return (
    <section className={`body-section doc-info color-back ${esDocClass}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column has-text-centered-tablet">
          <div
            dangerouslySetInnerHTML={createHtml(converter.makeHtml(data.text))}
          ></div>
          {/* <ButtonGroup isCentered>
            {props.doctors.map((dr, i) => (
              <Button key={i} buttonText={dr.docName} href={dr.title} contained />
            ))}
          </ButtonGroup> */}
        </div>
        <div className="column is-4"></div>
      </div>
      <div className={`columns our-docs is-multiline has-${docLength}-docs`}>
        {data.images.map((image, i) => {
          return (
            <>
              {i % 2 === 0 && <div className="column is-4"></div>}
              <div key={image.doctor.url} className="column is-7 doc-column">
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>
                <Link to={image.doctor.url}>
                  <h5 className="image-caption doctor">
                    {image.doctor.caption}
                  </h5>
                </Link>
              </div>
              {i % 2 === 0 && <div className="column is-1"></div>}
              {i % 2 === 1 && <div className="column is-4"></div>}
            </>
          )
        })}
      </div>
    </section>
  )
}

export default MeetOurDoctors
